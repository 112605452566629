.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* App css */
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Rowdies:wght@700&display=swap');
body {
  font-family: "Poppins", sans-serif;
}
.blockchainpets-navbar {
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
  font-family: "Poppins", sans-serif;
  background: white;
}
.blockchainpets-navbar .navbar-brand img {
  height: 50px;
}

.blockchainpets-navbar .nav-link {
  font-weight: bold;
  margin: 0px 10px;
}

.nav-profile,
.nav-wallet {
  font-size: 1.4rem;
}
.blockchainpetsheader {
  height: 90vh;
  /* background: url(https://images.unsplash.com/photo-1528459801416-a9e53bbf4e17?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2024&q=80)
    center/cover; */
     /* background: url(https://images.unsplash.com/photo-1615715757401-f30e7b27b912?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80)
    center/cover; */
    background: url(https://i.imgur.com/Jv4rPjV.png) repeat 0 0;;
    animation: slide 30s linear infinite;
  }
  @keyframes slide {
    from { background-position: 0 0; }
    to { background-position: -670px -670px; }
}
.table-bg-white{
  background-color: white;
  color: #282c34;
  
}

@media (min-width: 992px){
  .container-admin-lg{
    max-width: 1100px;
  }
}
@media (min-width: 1200px){
  .container-admin-lg{
    max-width: 1300px;
  }
}

.ts-header{
  text-shadow: 2px 2px 3px #f29555;
}
.overlay-blockchainpets-header {
  /* background: linear-gradient(
    180deg,
    rgb(255 255 255 / 0%) 0%,
    rgba(255, 255, 255, 0) 10%,
    rgba(255, 255, 255, 0) 90%,
    rgb(255 255 255) 100%
  ); */
  background: #00000080;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
}
/* .blockchainpetsheader-img{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  object-fit: cover;
  object-position: right;
  width: 100%;
  height: 100%;

} */

.bcp-title1{
  font-size: 2.5rem;
  font-weight: bold;
}
.bcp-title {
  font-size: 3.5rem;
  font-weight: bold;
}
.bcp-subtitle {
  font-size: 1.5rem;
  color: rgb(148, 148, 148);
}
.blockchainpets-img-box img.nft-img {
  width: 320px;
  border-radius: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  max-height: 340px;
  object-fit: cover;
  object-position: top;
}
.blockchainpets-img-box {
  width: 340px;
  border-radius: 20px;
  background-color: white;
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 10px 0px;
}

.blockchainpets-img-box .dog-img {
  width: 40px;
  height: 40px;
  display: inline-block;
  /* border-radius: 50%; */
  object-fit: cover;
}
.blockchainpets-img-box .eth-img {
  width: 40px;
  height: 40px;
  display: inline-block;
  /* border-radius: 50%; */
  object-fit: cover;
}
.avi-p1 {
  font-weight: bold;
  margin: 0;
  font-size: 0.8rem;
}
.avi-p1.text-primary{
  font-size: 1rem;
}
.gen-i {
  font-size: 0.8rem;
  color: rgb(148, 148, 148);
}
.blockchainpets-btn {
  background-color: rgb(32, 129, 226);
  padding: 10px 20px;
  color: white;
  border-radius: 10px;
  display: inline-block;
  margin-right: 5px;
  width: 140px;
  text-align: center;
  border: 1px solid rgb(32, 129, 226);
  cursor:pointer;
}
.blockchainpets-btn-wide {
  display: block;
  width: 50%;
  margin: 15px auto;
}
.blockchainpets-btn-white {
  color: rgb(32, 129, 226);
  background-color: rgba(255, 255, 255, 0);
}
.blockchainpets-btn:hover {
  text-decoration: none;
  color: white;
}
.blockchainpets-btn-white:hover {
  color: rgb(32, 129, 226);
}
.nft-showcase {
  padding: 60px 0;
}
.nft-showcase .owl-ns-img {
  height: 340px;
  object-fit: cover;
  object-position: center;
  border-radius: 20px;
  border: 2px solid rgb(112, 191, 255);
}
.social-f-link img {
  width: 60px;
  height: 60px;
  margin: 10px;
  margin-left: 0;
  margin-top: 0;
  border-radius: 50%;
  border: 3px solid rgba(128, 128, 128, 0);
  transition: 0.5s ease-in-out;
}
/* .social-f-link i{
  margin: 10px;
  margin-left: 0;
  margin-top: 0;
  border-radius: 50%;
  border: 3px solid rgba(128, 128, 128, 0);
  transition: 0.5s ease-in-out;
  font-size: 30px;
color: #0084ff;

} */
.social-f-link i:hover{
color: #0084ff;
}

.social-f-link:hover {
  text-decoration: none;
}
.blockchainpets-f-links a {
  color: #0084ff;
  display: block;
  margin: 5px 20px;
  margin-left: 0;
}
.blockchainpets-f-logo img {
  height: 40px;
}
.f-copyright {
  background: rgb(237, 249, 255);
  margin: 0;
  padding: 30px 0;
}
.overlay-blockchainpets-footer {
  background: linear-gradient(
    0deg,
    rgb(255 255 255 / 0%) 0%,
    rgba(255, 255, 255, 0) 10%,
    rgba(255, 255, 255, 0) 90%,
    rgb(255 255 255) 100%
  );
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
}
.blockchainpets-footer {
  background: rgb(237, 249, 255);
  /* height: 500px; */
  padding: 120px 0 60px 0;
}

.owl-carousel .owl-nav button.owl-next{
  top: 42%;
    position: absolute;
    font-size: 2.5rem;
    right: -25px;
    color: #444444;
    background: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: 1px solid rgb(173, 173, 173);
    outline: none;
}
.owl-carousel .owl-nav button.owl-prev{
    top: 42%;
    position: absolute;
    font-size: 2.5rem;
    left: -25px;
    color: #444444;
    background: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: 1px solid rgb(173, 173, 173);
    outline: none;
}

.owl-next span,.owl-prev span{
  position: relative;
  bottom: 7px;
}

.htb-item div i {
  font-size: 2rem;
  border: 4px solid #008cdd;
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  margin: 0px 15px;
  color: #008cdd;
}

.htb-item {
  display: flex;
  align-items: center;
  text-align: left;
  margin: 26px 0px;
}
.htb-blockchainpets{
  padding: 80px 0;
}

.htb-item  p {
  font-size: 20px;
  margin-bottom: 0px;
}
.at-points-container .apc{
  font-size: 2.2rem;
  font-weight: bold;
  font-family: 'Rowdies', cursive;
  color: #005eac;
}
.b-space{
  padding-left: 12px;
  display: inline-block;
}
.blockchainpets-nft-imgs img{
  width: 100%;
  height: 400px;
  object-fit: cover;
  object-position: center;   
   border: 3px solid pink;
}
.blockchainpets-nft-imgs img.rm{
   border-right: 6px solid pink;
}
.blockchainpets-nft-imgs img.rl{
  border-left: 6px solid pink;
}
.blockchainpets-nft-imgs img.rt{
  border-top: 6px solid pink;
}
.blockchainpets-nft-imgs img.rb{
  border-bottom: 6px solid pink;
}
.bs-1{
  box-shadow:  0px 0px 10px 5px rgb(255 143 143 / 78%);}

  .cs-imgs{
    width: 80px;
    margin-top: 30px;
    
  }
  .bcpi-p{
    font-size: 1.2rem;
    padding: 20px;
    margin-top: 20px;
  }
  .bcp-nft-instructions{
    padding: 80px 0;
    background-color: rgba(255, 251, 244, 0.718);
  }
  .bcp-active-nav-item{
    color:black;
  }
  .bcp-img-box-container:hover{
    text-decoration: none;
    color: black;
  }
  .bcp-img-box-container{
    color: black;
  }
  .marketplace{
    padding-top: 80px;
  }
  .create-nfts{
    padding-top: 30px;
    padding-bottom: 40px;
  }
  /* .create-nfts .show-case-design img{
    width: 100%;
    border: 4px solid #000000;
    border-radius: 24px;
  } */
.show-case-design img{
  position: absolute;
}
  .has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}
.nft-details-img{
  width: 100%;
}
.nft-details{
  padding: 25px 0;
  background: url(https://images.unsplash.com/photo-1508614999368-9260051292e5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80) center/cover
}
.nft-details-container{
  padding: 20px;
  border: 1px solid rgb(49, 214, 255);
  border-radius: 15px;
  background: white;
  
}
.detail-pill-p1,.detail-pill-p2{
  margin: 0;
  text-align: center;
}
.detail-pill-p1{
  font-weight: bold;
  color: #005aaf;
}
.detail-pill-p2{
  color: rgb(72, 72, 255);
}
.detail-pill{
  background-color: #c1e8ff;
  border: 1px solid rgb(0, 128, 255);
  padding: 10px;
  border-radius: 20px;
  min-width: 187px;
  margin: 10px 5px 0px 5px;
}
.ddr-p1{
  font-size: 2rem;
  font-weight: bold;
}
.ddr-price{
  border: 1px solid rgb(49, 214, 255);
  border-radius: 20px;
  padding: 15px 30px;
  background-color: white;
}
.ddr-price img{
 width: 60px;
}
.ddrp-p1{
  font-size: 2rem;
  margin-bottom: 8px;
  font-weight: bold;
  color: #313131;
  
}
.ddrp-p2{
  font-size: 40px;
  margin-bottom: 0;
  padding-left: 10px;
  color: #333333;
}
.ddr-p2 span{
color: #0084ff;
}
.contact-admin{
  padding: 60px 0;
}
.ca-title{
  font-size: 3rem;
  font-weight: bold;

}
.sell-nfts{
  padding: 20px 0;
}
.snft-p1{
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
  margin-top: 30px;
}
.profile-section{
  padding: 80px 0;
}

.profile-section .profile-img{
  width: 100%;
}

.psp1{
  font-size: 2rem;
  font-weight: bold;
}
.psp2{
  font-size: 1.5rem;
  color: #313131;
}
.psp3{
  font-size: 1.5rem;
}
.connect-wallet-btn{
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.connect-wallet-btn:active,.connect-wallet-btn:focus{
  border: none;
  outline: none;
}
.breeding-section{
  padding: 60px 0;
}
@media (min-width: 1200px){
  .breeding-container{
    max-width: 1500px;
  }
}
.breeding-col-right{
  border-left: 1px solid rgb(153, 153, 153);
}
.text-pink{
  color: rgb(255, 111, 135);
}
.blockchainpets-img-box-breed{
  border: 5px solid blue;
}

.table-container{
  max-height: 600px;
  overflow-y: scroll;
}
.remove-listing{
  position: absolute;
  right: 35px;
  border-radius: 50%;
  border: none;
  background-color: rgb(79, 182, 255);
  color: white;
  width: 30px;
  height: 30px;
  top: 10px;
  
}
.bcp-admin-login{
  padding: 80px 0;
}
.bcp-al-wrapper{
  padding: 40px;
  border-radius: 30px;
  box-shadow: 0px 0px 9px 2px #bfbfbf;
}


#d-mouth{
  top: 74px;
  left: 84px;
  width: 188px;
  height: 208px;
}
#d-back{
  left: 85px;
  top: 203px;
  width: 393px;
  height: 189px;
  z-index: 1;
}
#d-legsfeet{
  top: 250px;
    left: 125px;
    width: 395px;
    height: 310px;
}
#d-eyes{
  top: 133px;
  left: 87px;
  z-index: 1;
  width: 120px;
  height: 73px;
}
#d-head{
  top: 69px;
    left: 84px;
    width: 177px;
    height: 183px;
    
}
#d-collar{
  top: 193px;
  left: 85px;
  z-index: 2;
  width: 177px;
  height: 129px;
}
#mf-d-tail{
  /* animation: tailmoving 5s infinite;
  z-index: -20; */
  top: 295px;
  left: 454px;
  width: 151px;
  height: 141px;
}

#d-eyelashes{
  left: 87px;
    top: 133px;
    width: 120px;
    height: 73px;
}
.dog-container-col{
  height: 550px;
}
.grd-1{
  display: none;
}
.grd-2{
  display: block;
}
/* .show-case-design{
  right: 0px;
  transform: scale(1);
} */

#d-background{
  z-index: -1;
  width: 100%;
  height: 585px;
  top: 80px;
  left: 61px;
  border-radius: 30px;
  animation-duration: 10s;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	animation-timing-function: linear; 
  animation-name: bganimation;
}

#d-shades {
  width: 229px;
  left: 73px;
  top: 59px;
  z-index: 2;
}
@keyframes bganimation {
  0% {
    background: #ffa39c;
  }
  10%{
      background: #e9a8ff;
  }
  20%{
      background: #ffeea8;
  }
  30%{
      background: #a2f4ff;
  }
  40%{
      background: #d6ffab;
  }
  50% {
    background: #a6cafd;
  }
  60%{
      background: #ffb4ff;
  }
  70%{
      background: #ffb2dbcf;
  }
  80% {
    background: #cbffb0;
  }
  90% {
    background: #fce7b5;
  }
  100% {
    background: #ffdeb2;
  }
}

.show-case-design-box{
  transform: scale(0.5);
  position: relative;
  right: 22%;
}
.show-case-design-box-details{
  transform: scale(0.8);
  position: relative;
  right: 5%;
}
.scdb-container{
  animation-duration: 10s;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	animation-timing-function: linear; 
  animation-name: bganimation;
  border-radius: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  height: 320px;
  border-bottom: 1px solid rgb(255, 255, 255);
}
.scdb-container-details{
  animation-duration: 10s;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	animation-timing-function: linear; 
  animation-name: bganimation;
  border-radius: 20px;
  margin-bottom: 10px;
  height: 480px;
  border-bottom: 1px solid rgb(255, 255, 255);
}
@media (max-width: 1200px) {
  .psp3{
    font-size: 20px;
  }
}
@media (max-width: 600px) {
  .show-case-design{
    right: 141px;
    transform: scale(0.55);
    width: 638px;
  }
  .psp3{
    font-size: 16px;
  }
  .dog-container-col{
    height: 500px;
  }
  .grd-1{
    display: block;
    margin-bottom: 10px;
  }
  .grd-2{
    display: none;
  }
  .blockchainpets-navbar .navbar-brand img {
    height: 40px;
  }
  .genesequence-container{
    position: relative;
    top: -135px;
  }
  .psp1{
    font-size: 1.1rem;
    padding-top: 10px;
  }
}
/* ******Trying potential animations***** */

/* @keyframes tailmoving{
  0%{
    transform: rotate(0deg);
  }
  25%{
    transform: rotate(10deg);
    left: 400px;
    top: 201px;
  }
  75%{
    transform: rotate(-10deg);
    left: 400px;
    top: 201px;
  }
  100%{
    transform: rotate(0deg);
  }
} */

.dog-selection{
  position: absolute;
  z-index: 10;
  /* left: 0; */
  /* right: 0; */
  /* top: 0; */
  /* bottom: 0; */
  cursor: pointer;
  width: 340px;
  height: 400px;
}
.border-blue{
  border: 5px solid blue;
}
.own-self{
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 10px;
  color: rgb(0, 213, 0);
}
#showcase-video{
  width:100%;
  border-radius: 20px;
  box-shadow: rgb(84 173 255 / 38%) 0px 0px 20px 10px;
}
.alert{
  display: none;
}
th.user{
  width: 5%;
}
th.message{
  width: 65%;
}
th.subject{
  width: 10% !important;
}
th.email{
  width: 10%;
}
th.name{
  width: 10%;
}
#exTab1 .nav-pills > li > a {
  border-radius: 0;
}
#exTab1 .tab-content {
  color : white;
  background-color: #428bca;
  padding : 5px 15px;
}
#exTab1 .nav>li>a:hover{
    text-decoration: none;
    background-color: #eee;
}
#exTab1 .nav-pills>li a.active,#exTab1  .nav-pills>li a.active>a:hover,#exTab1  .nav-pills>li a.active {
    color: #fff;
    background-color: #428bca;
    text-decoration: none;
}
#exTab1 .nav>li>a {
    position: relative;
    display: block;
    padding: 10px 15px;
    text-decoration: none;
}
.inputimg{
  float: right;
  height: 60px;
}
.fc-marketplace {
  font-size: 14px;
  height: 30px;
  padding: 0 10px;
}
.bcp-profile-info{
  background: white;
  /* border: 1px solid black; */
  border-radius: 25px;
  padding: 15px 15px 18px 15px;
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
}
#search2{
  padding: 10px;
  /* background: #c77878; */
  border-radius: 25px;
  /* color: white; */
  /* border: 2px solid #200202; */
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  animation-name: bganimation;
  box-shadow: rgb(4 17 29 / 25%) 0px 2px 8px 0px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
#search1{
  background: white;
  padding: 16px 10px 10px 10px;
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
  /* margin-bottom: 15px; */
  border-radius: 25px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: 1px solid #b9b9b9;
}
.header-sticky{
  position: sticky;
  top: 0;
  background: white;
  left: 0;
  right: 0;
}
.loading-page{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: #ffd392;
}
@keyframes loading {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.3, 1.3);
  }
  100% {
    transform: scale(1, 1);
  }
}
.rotate {
  animation: loading 2s infinite ease;
}
/* #minprice::-webkit-outer-spin-button,
#minprice::-webkit-inner-spin-button,#maxprice::-webkit-outer-spin-button,
#maxprice::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; 
}

#minprice,#maxprice{
    -moz-appearance:textfield; 
} */
.marketplace-loading{
  display: none;
}